.modal-win{
    border: none;
    border-radius: 10px;
    /* padding: 5px !important; */
    padding-top: 9px !important;
    color: rgb(36, 35, 35);
    /* background-color: lightgrey !important; */
    /* width: auto; */
    /* display: flex; */
    /* flex-direction: column; */
    max-width: 500px !important;
    min-width: 360px;
    .close-btn{
        position: absolute;
        top: 0;
        right: 0;
        margin: 5px;
        border: 1 px solid black;
        background: none;
        font-size: 18px;
        padding: 1px 6px;
        border-radius: 50%;
        cursor: pointer;
        @media (max-width:900px) {
            font-size: 14px;
            padding: 1px 3px;
        }

    }
    
}
.img-c{
    // background-image: url('../../assets/logo.svg');
    // background-size: cover;
    /* opacity: 0.5 !important; */
    // background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.header{
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.img-1,.img-2{
    width: 50px;
    height: 50px;
}
.img-2{
    transform: scaleX(-1);
}
.menu-box{
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}
ul{
    list-style-type: none;
    /* min-height: 220px; */
    display: flex;
    flex-direction: column;
    align-items:center ;
    gap:10px;
    padding: 10px 0;
    /* justify-content: space-between; */

}
.p-0{
    /* border:2px solid black; */
    padding: 0 !important;
    /* border-radius: 3px; */
}
.p-0:not(:last-child){
    border-right: none;
}
.he1{
    text-align: center;
    /* padding-bottom: 10px; */
    /* font-weight: 700; */
}
.he1:hover{
    color: orange;
}
.he2{
    color: rgb(83, 83, 83);
    border-bottom: 2px solid rgb(83, 83, 83);
    padding: 0 5px;
    padding-top: 5px;
}
li{
    color: rgb(96, 96, 96);
    /* width: fit-content; */
    /* margin: auto; */
}
li:hover{
    /* width: 100%; */
    align-items: center;
    /* margin: auto; */
    /* background-color: antiquewhite; */
    color: black;
    cursor: pointer;
    flex:1
}
.tab-box{
    width: auto !important;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.3);
    button{
        opacity: 1 !important;
        background-color: rgb(80, 80, 80);
    }
    button.Mui-selected{
        background-color: rgb(0, 0, 0);
        opacity: 1 !important;
    }
    .MuiTabs-indicator{
        background-color: #acadb0 !important;
        height: 3px;
    }
}
@media (max-width:600px){
    .modal-win{
        max-width: 500px !important;
    }
    .tab-box{
        width: 100% !important;
    }
}
@media (max-width:500px){
    .modal-win{
        max-width: 420px !important;
    }
}
@media (max-width:400px){
    .modal-win{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.tab-panel{
    div{
        padding-left: 0 !important;
        padding-right: 0 !important;
        display: flex !important;
        flex-direction: column;
    }
    p{
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        min-height: 200px;
        flex-direction: column;
        span{
            width:100%;
            padding: 5px 0;
        }
        span:hover{
            background-color: rgba(208, 206, 206, 0.407);
            cursor: pointer;
            border-radius: 10px;
        }
    }


}
.mdl-btn{
    border: none;
    // background: none;
    background: #fff;
    // margin:  auto;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 700;
    color: rgb(2, 124, 232);
    text-decoration: underline;
    width: 100%;
    cursor: pointer;
}