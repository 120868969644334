@import "../../app.scss";

.ouroffer{
    background: linear-gradient(180deg, #04010e, rgba(83, 83, 83, 0.4823529412));

    .over{
        position: relative;
        background: linear-gradient(180deg, #04010e, rgba(83, 83, 83, 0.4823529412));
    }
}

.pitch-box {
    position: relative;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    color: white; /* Set text color to white or a color that works well with your background */
    background-repeat: no-repeat;
}
.meat{
    background-image: url('../../assets/4.jpg');
}

.over {
    background-color: rgba(0, 0, 0);
    opacity: 0.75;
    width: 100%;
    height: 100%; /* Cover the entire height of the container */
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
}
.br{
    // display: none;
    margin-bottom: 10px;
}
.ptch-content {
    position: relative;
    z-index: 2; /* Make sure the content appears above the overlay */
    padding: 20px 0px; /* Adjust padding as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 2;
    line-height: 30px;
    // width: 80%;
    // gap:10px;
    .pitch-text{
        font-size: 20px;
        font-weight: 500;
        color: rgb(229, 229, 229);
        .cus{
            text-decoration: underline;
            cursor: pointer;
        }
        .cus:hover{
            color: orange;
        }
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
    }
}
h1{

    font-size: 27px;
}
.text-wrapper {
    text-shadow: 3px 2px 5px rgba(128, 128, 128, 0.5); /* Adjust the shadow properties as needed */
    padding: 15px; /* Add padding to the wrapper if necessary */
    width: 90%;
}
.me-dot{
    // border: 2px solid red;
    display: block;
    border-radius: 50%;
    background-color: rgb(234, 52, 52);
    width: 20px;
    height: 20px;
    // margin: 0 20px;
}
.dot-box{
    margin: 20px 0;
    display: flex;
    gap: 20px;
    justify-content: center;
}

@include tablet{
    .text-wrapper{
        width: 100%;
    }
    h1{
        font-size: 24px;
    }
    .pitch-text{
        font-size: 14px !important;
    }
    .me-dot{
        width: 12px;
        height: 12px;
    }
    .dot-box{
        gap:15px !important;
        margin: 10px 0;
    }


    .offer-text {   
        h1{
            margin-bottom: 10px !important;
        }
    }

    .category{
        gap:60px !important;
        h1{
            font-size: 16px !important;
            margin-bottom: 0 !important;
        }
        img{
            height: 70px !important;
            width: 70px !important;
    
        }
        .img-div{
            height: 120px !important;
            width: 120px !important;
        }
    }

    .rounded-button {
        padding: 5px 10px !important;
        font-size: 14px !important;
      }


}

@include mobile{
    .ptch-content{
        line-height: 20px !important;
    }
    h1{
        line-height: 25px;
    }
    .text-wrapper{
        width: 100%;
    }
    h1{
        font-size: 18px;
    }
    .pitch-text{
        font-size: 12px !important;
    }

    .offer-text {   
        h1{
            margin-bottom: 10px !important;
        }
    }

    .category{
        gap:30px !important;
        h1{
            font-size: 14px !important;
            margin-bottom: 0 !important;
        }
        img{
            height: 40px !important;
            width: 40px !important;
    
        }
        .img-div{
            height: 90px !important;
            width: 90px !important;
        }

    }


}


// OUR OFFERING CSS

.black{
    background-image: url('../../assets/blackbg.jpg');
    
}
.w{
    // border-radius: 0;
    // border: 2px solid;
    // width: 90px;
    // height: 10px;
    background-color: antiquewhite;
}
// .w-box{
//     gap:50px
// }

.offer-text {   
    text-shadow: none;
    h1{
        margin-bottom: 20px;
    }
}
.category{
    display: flex;
    gap:90px;
    align-items: center;
    justify-content: center;
    // margin: 50px 0 0 0;
    h1{
        font-size: 24px;
        letter-spacing: 1px;
        color: black;
        // margin-top: 10px;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 0px;
    }
    img{
        height: 120px;
        width: 90px;

    }
    .img-div{
        background-color: rgba(243, 243, 243, 0.772);
        border-radius: 50%;
        height: 190px;
        width: 190px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .img-div:hover{
        background-color: rgba(254, 253, 253, 0.915);
        cursor: pointer;
        box-shadow: 1px  2px  3px rgba(255, 255, 255, 0.109); /* Adjust the shadow properties as needed */
    }
}

.rounded-button {
    border: none;
    margin-top: 30px;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: rgba(255, 166, 0, 0.833);
    color: black;
    text-decoration: none;
    transition: background-color 0.3s, border-radius 0.3s, color 0.3s, font-size 0.3s;
    font-size: 18px;
    cursor: pointer;
  }

  /* Hover effect */
  .rounded-button:hover {
    background-color: orange;
    // color: orange;
    border-radius: 30px;
    // font-size: 20px;
     /* Increase text size on hover */
  }
