@import "../../app.scss";

.navbar {
  // margin: 20px 0;
  // margin-top: 20px;
  position: sticky;
  top:0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo-span{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 220px;
    // border: 2px solid lightgrey;
    padding: 1px;
    border-radius: 5px;
  }
  .logo{
    width: 125px;
    // height: 80%;
    cursor: pointer;
  }
  
  // height: 50px;
  background: linear-gradient(360deg, #04010e 0%, rgba(83, 83, 83, 0.682353) 100%);
  padding: 10px 60px;
  background-color:#04010e ;
  // border-bottom: 2px solid white;
  .social {
    // max-width: 1366px;
    // max-width: 100%;
    display: flex;
    // margin: auto;
    justify-content: center;
    gap: 60px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    height: 100%;
    font-weight: 400 !important;
    // background: #fffdfd;
    // box-shadow: inset 5px 5px 20px rgba(0, 0, 0, 0.5);
    // margin: 20px 0;
    a{
      color: rgb(255, 252, 252);
      margin: auto 0 ;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: 10px
    }
    .activee{
      // border: 2px solid black;
      text-decoration: underline;
      font-weight: 700;
    }
    @media(max-width:1200px){
      a{
        font-size: 14px;
      }
    }
    a:hover{
      background-color: rgb(217, 217, 217);
      border-radius: 5px;
      color: rgb(76, 75, 75);

      // opacity: 0.2;
    }


    @include mobile {
      justify-content: flex-end;
      padding: 20px;
    }

    span {
      // font-weight: bold;
      // display: flex;
      // align-items: center;

      @include mobile {
        display: none;
      }
    }

    .social {
      display: flex;
      // gap: 20px;
      justify-content: center;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}


@media (max-width:1000px){
  .navbar{
    display: none;
  }
}