@import "../../app.scss";
.services {
  // background: linear-gradient(180deg, #0c0c1d, #111132);
  background: linear-gradient(180deg, #04010e, #5353537b);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .textContainer {
    flex: 1;
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 20px;

    @include mobile {
      align-self: center;
      text-align: center;
      flex-direction: column;
    }

    p {
      font-weight: 200;
      font-size: 20px;
      color: gray;
      text-align: right;
    }

    hr {
      width: 500px;
      border: none;
      border-top: 0.5px solid gray;

      @include mobile {
        width: 300px;
      }
    }

  }
  .titleContainer {
    margin-top: 50px;
    @include desktop{
      margin-top: 30px;
      // font-size: 20px;
    }
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    .intro-box{
      display: flex;
      flex-direction: column;
      align-items: center;
      // height: 80px;
      margin-top: 30px;
      @include desktop{
        // height: 60px;
        margin-top: 10px;
      }
      .sub-div{
        // width: 60%;
        min-height: 250px;
        display: flex;
        justify-content: center;
        margin:  auto 300px ;
        @include desktop{
          margin:  auto 150px ;
        }
        .about-img-2{
          display: none;
        }
        .about-img{
          width: 700px;
          height: 250px;
          border-radius: 50px;
          @include desktop{
            width:  500px ;
            height: 250px;
          }

        }
        .big-span{
          background-color: rgba($color: #9c90c5, $alpha: 0.2);
          font-size: 20px;
          padding: 0 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          gap: 20px;
          margin-left: 25px;
          border-radius: 50px;
          text-align: center;
          line-height: 30px;
          min-height: 250px;
          @include desktop{
            font-size: 18px;
            padding: 0 30px;
            gap:10px;
            margin-left: 15px;
            min-height: 200px;
          }
          .sub-span{
            border-radius: 35px;
            margin-top: 10px;
            padding: 20px;
            background-color: rgba($color: #a09fa2, $alpha: 0.09);
            b{
              margin: 0;
            }
            font-size: 44px;
            display: flex;
            align-items: center;
            gap: 20px;
            @include desktop{
              padding: 10px;
              font-size: 30px;
            }
          }
        }
        .my-img{
          width: 150px;
          height: 60px;
          border-radius: 10px;
          @include desktop{
            width: 120px;
            height: 40px;   
          }
          // margin-top: 40px;
        }
      }
      @media(max-width:1200px){
        .big-span{
          padding: 5px !important;
          font-size: 14px !important;

          b{
            font-size: 20px !important;
            // bord
          }
          .my-img{
            width: 90px;
            height: 30px;
          }
          .sub-span{
            gap: 5px !important; 
            padding: 5px 7px !important;
          }
        }
      }


      @media(max-width:900px){
        .sub-div{
          margin: 0px 10px;
          flex-direction: column;
          align-items: center;
          .about-img{
            display: none;
          }
          .big-span{
            font-size: 12px !important;
            line-height: 20px;
          }
          .about-img-2{
            display: block;
            width: 240px;
            height: 190px;
            border-radius: 50px;
            margin: 10px 0 20px 0;
          }
          b{
            font-size: 22px;
          }
        }
      }

    }



    @include mobile {
      width: 100%;
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      @include mobile {
        flex-direction: column;
        text-align: center;
        gap: 20px;
      }
      p{
        max-width: 750px;
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
        
      }

      img {
        width: 300px;
        height: 100px;
        border-radius: 50px;
        object-fit: cover;

        @include mobile {
          width: 200px;
          height: 50px;
        }
      }

      h1 {
        font-size: 96px;
        font-weight: 100;
        @include desktop {

            font-size: 38px;
          
        }
        @include mobile {
          font-size: 36px;
        }
      }

      button {
        width: 300px;
        height: 100px;
        border-radius: 50px;
        background-color: orange;
        border: none;
        font-size: 24px;
        cursor: pointer;

        @include mobile {
          font-size: 16px;
          width: 150px;
          height: 50px;
        }
      }
    }
  }
  .aboutContainer{
    max-height: 400px;
    padding-bottom: 20px;
    @include desktop{
        max-height: 350px;
        .box{

        }
    }
  }
  .listContainer {
    // flex: 2;
    display: flex;
    // max-width: 1366px;
    margin: 0 auto;
    
    flex-direction: column;
    @include mobile {
      flex-direction: column;
      width: 100%;
    }
    img{
      width: 150px;
      background-color: lightgray;
      border: 2px solid grey;
      padding: 10px 20px;
      border-radius: 20px;

    }
    .box-2{
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      margin-left: 40px;
      gap:20px;
      .desc{
        max-width: 650px;
      }
      button{
        width: 150px;
        border-radius: 5px;
      }
    }

    .box {
      border-radius: 15px;
      padding: 25px;
      border: 0.5px solid gray;
      display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      margin: 20px 0;

      // @include mobile {
      //   border: none;
      //   align-items: center;
      //   gap: 20px;
      //   padding: 10px;
      // }

      // p {
      //   @include mobile {
      //     display: none;
      //   }
      // }

      button {
        padding: 10px;
        background-color: orange;
        border: none;
        cursor: pointer;

        // @include mobile {
        //   background-color: transparent;
        //   border: 1px solid orange;
        //   border-radius: 5px;
        //   color: orange;
        //   width: 50%;
        //   padding: 5px;
        // }
      }
    }
  }
}


//services responsiveness
@include tablet{
  .services{
      .title{
        h1{
          font-size: 24px !important;
        }
        p{
          font-size: 14px !important;
          margin-bottom: 5px !important;
        }
    }
    .listContainer{
      .box{
        padding: 15px;
        img{
          width:100px;
        }
      }
      .box-2{
        // width: max-content;
        h2{
          font-size: 20px !important;
        }
        .desc{
          font-size: 12px !important;
        }
        button{
          width: 80px;
          padding: 5px;
          font-size: 12px;
        }
      }
    }
  }
}
@include mobile{
  .services{
      padding: 0 10px ;
      .title{
        h1{
          font-size: 20px !important;
        }
        p{
          font-size: 12px !important;
          margin-bottom: 5px !important;
        }
    }
    .listContainer{
      .box{
        padding: 10px;
        // gap:10px;
        img{
          width:90px;
        }
      }
      .box-2{
        // width: max-content;
        gap:0 !important;
        margin-left: 10px;
        h2{
          font-size: 16px !important;
        }
        .desc{
          font-size: 10px !important;
          margin: 5px 0 10px 0 !important;
        }
        button{
          width: 60px;
          padding: 5px;
          font-size: 10px;
        }
      }
    }
  }
}