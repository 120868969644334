@import "../../app.scss";

.formContainerT{
  // .formContainerT {
    max-width: 400px;
    margin: 0 auto;
  // }
  
  form {
    display: flex;
    flex-direction: column;
    // padding: 20px;
    // border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  input,
  textarea {
    margin-bottom: 16px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 12px;
    font-size: 16px;
    background-color: rgb(242, 242, 242);
    color: black;
    border: none;
    border-radius: 4px;
    border: 2px solid black;
    cursor: pointer;
  }
  
  button:hover {
    background-color: black;
    color: #fff;
  }
  
  .error,
  .success {
    margin-top: 12px;
    color: red; /* or your preferred color for errors */
  }
  
}
.contact {
  // height: 100vh;
  // max-width: 1366px;
  margin: auto;
  display: flex;
  align-items: flex-start;
  gap: 50px;
  padding: 20px 50px;
  background: linear-gradient(360deg, #04010e, rgba(83, 83, 83, 0.4823529412));

  @include mobile {
    width: 100%;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:0px
  }

  .textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
    // flex: 1;
    // position: relative;
    h2{
      display: flex;
      gap: 10px;
    }

    .phoneSvg {
      stroke: orange;
      position: absolute;
      margin: auto;
      z-index: -1;
      top:-120px;
      display: none;
    }


    @include mobile {
      gap: 20px;
      text-align: center;
      align-items: center;
      margin-top: 70px;
    }

    h1 {
      font-size: 40px;
      line-height: 40px;

      @include mobile {
        font-size: 36px;
      }
    }

    span {
      font-weight: 300;
      display: flex;
      flex-direction: column;
      gap: 35px;
    }
    @include mobile{
      span{
        gap:25px;
        align-items: center;
        justify-content: center;
      }
      h1{
        display: flex;
        align-items: center;
        gap:10px;
      }
      h2{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .item{
        h2{
          margin-bottom: 10px !important;
        }
      }
    }

  }

  .formContainer {
    flex: 1;
    // position: relative;
    
    @include mobile {
      padding: 50px;
      width: 100%;
    }


    // .phoneSvg {
    //   stroke: orange;
    //   position: absolute;
    //   margin: auto;
    //   z-index: -1;
    // }

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      input,
      textarea {
        padding: 20px;
        background-color: transparent;
        border: 1px solid white;
        color: white;
        border-radius: 5px;

        @include mobile {
          padding: 10px;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap:10px;
        padding: 20px;
        border: none;
        background-color: orange;
        cursor: pointer;
        font-weight: 500;
        @include mobile {
          padding: 10px;
        }
      }
    }
  }
}
footer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}