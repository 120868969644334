@import "../../app.scss";

.hero {
  height: calc(100vh - 100px);
  overflow: hidden;
  // background: linear-gradient(180deg, #0c0c1d, #111132);
  background: linear-gradient(180deg, #04010e, #000);
  position: relative;

  .wrapper {
    // max-width: 1366px;
    height: 100%;
    margin: auto;
  }

  .imageContainer {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    @include mobile {
      height: 50%;
      width: 100%;
      top: unset;
      bottom: 0;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .slidingTextContainer {
    position: absolute;
    font-size: 30vh;
    bottom: -40px;
    white-space: nowrap;
    color: #ffffff09;
    width: 50%;
    font-weight: bold;
  }
}
