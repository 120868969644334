@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap");
@import "../../app.scss";
.App-header {
  font-size: 1rem;
  text-align: center;
  font-family: "Oswald", sans-serif;
  padding-bottom: 2rem;
}
.carousel-images {
  position: relative;
  border-radius: 10px;
  height: 50vh;
  width: 50%;
  /* max-width: 650px; */
  /* margin: auto; */
  overflow: hidden;
}
.cap-img{
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: center;
    gap:40px;
}
.cap-img h2{
    /* width: 50%; */
}
.carousel{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 50px
;}
.carousel-images img {
  width: 99%;
  height: 99%;
  border-radius: 8px;
  /* border: #ff00008e solid 2px; */
}
.slide_direction {
  display: flex;
  justify-content: space-between;
}
.content{
  display: flex;
  flex-direction: column;
  width: 50%;
  
  h2 {
    font-size: 30px;
    letter-spacing: 2px;
    margin-bottom: 5px;
  }
  .span-1{
    border-bottom: 3px solid #737373;
    width: 75%;
    margin: 5px 0 ;
  }
  .span-2{
    width: 65%;
    border-bottom: 2px solid #737373;
    margin: 5px 0;
  }
  p{
    margin-top: 10px;
    font-size: 20px;
  }
}
.left,
.right {
  background-color: #fb666675;
  color: #fff;
  padding: 10px 8px 8px 13px;
  margin: 0 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 10px;
  height: 25px;
  width: 25px;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.carousel-indicator {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  z-index: 100;
  cursor: pointer;
}
.dot {
  background-color: #333;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.active {
  background-color: #ffffff;
}
@include tablet{
  @media (max-width: 900px) {
    .hero{
      height: auto !important;
    }
    h2{
      // display: none;
      font-size: 18px !important;
      letter-spacing: 0.5px !important;
      margin-bottom: 2px !important;
    }
    .span-1,.span-2{
      display: none;
    }
    .carousel{
      margin-top: 10px;
    }
    .cap-img{
      flex-direction: column;
      gap:10px
    }
    .content{
      width: 100%;
      text-align: center;
      p{
        font-size: 14px !important;
      }
      @include mobile{
        // display: none;
        p{
          font-size: 12px !important;
        }
      }

    }
    .carousel-images{
      width: 100%;
      height: auto;
      img{
        height: 350px;
        @media (max-width: 600px) {
          height: 200px;
        }
        // width: ;
      }
    }

  }
}