html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

body {
  background-color: #04010e;
  color: lightgray;
}

a {
  text-decoration: none;
  color: inherit;
}

.o-button {
  padding: 10px;
  background-color: orange;
  border: none;
  cursor: pointer;

  @media (max-width: 2000px) {
    background-color: transparent;
    border: 1px solid orange;
    border-radius: 5px;
    color: orange;
    width: 50%;
    padding: 5px;
  }
}

section {
  height: max-content;
  // width: 100vw;
  // scroll-snap-align: center;
  // overflow: hidden;
}
section:last-child{
  // height: 100vh;
//   overflow: visible;
//   scroll-snap-align: start;
}
@mixin mobile {
  @media (max-width: 738px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 1100px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 2000px) {
    @content;
  }
}

