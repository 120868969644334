@import "../../app.scss";

.box-1{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    /* justify-content: center; */

}
.box-1 .desc{
    border:2px solid white;
    margin-top: 20px;
    max-width: 740px;
    padding: 0px !important;
    min-height: 180px;
    border-radius: 20px;
    
}
.box-1 p{
    display: flex;
    margin: auto;
    /* width: 50%; */
    /* text-align: center; */
    gap: 20px;
}
.box-1 p img {
    width: 200px;
    height: 160px;
    border-radius: 20px;
}

.tab button{
    margin: 0 10px;
    color: rgb(155, 154, 154);
    
}
.tab button:hover{
    /* margin: 0 10px; */
    color: rgb(220, 220, 220);
    
}
.tab hr{
    color: white;
}
.tab button.Mui-selected{
    margin: 0 10px;
    color: rgb(255, 255, 255) !important;
    font-weight: 700;
}
.box-1 .MuiTabs-indicator{
    color: orange !important;
}

@include mobile{
    .tab button{
        padding: 0 !important;
        font-size: 12px;
    }
    .tab button:first-child{
        margin: 0;
    }
    .tab button:last-child{
        margin: 0;
    }
    .box-1 p{
        flex-direction:column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 13px;
        line-height: 20px;

    }
    .box-1 p img{
        min-width: 220px;
        height: 160px;
    }
    .desc{
        margin: 10px !important;
    }
}
