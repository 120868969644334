@import "../../app.scss";
.sidebar {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  -webkit-overflow-scrolling: touch;
  .logo-span{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 220px;
    // border: 2px solid lightgrey;
    padding: 1px;
    border-radius: 5px;
  }
  .logo{
    width: 125px;
    // height: 80%;
    cursor: pointer;
  }
  
  
  .bg {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background: white;

    @include mobile {
      width: 200px;
    }

    .links {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      a {
        font-size: 30px;

        @include mobile {
          font-size: 20px;
        }
      }
    }
  }

  button {
    z-index: 999;
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: fixed;
    top: 25px;
    left: 25px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
@media (max-width:1000px){
  .sidebar{
    display:flex;
  }
}